<script lang="ts" setup>
import { inject, onMounted, onUnmounted, ref, set, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useEventBus, useLocalStorage } from '@vueuse/core'
import BasicModal from './BasicModal.vue'
import { api } from '@/api'
import { trackRealtime } from '@/track'
import { $getToken } from '@/helpers'
import { getToday, sleep } from '@/utils'
import { STORAGE_ACTIVITY_MAP } from '@/constants'

const modal = ref(false)
const modalData = ref<any>(null)
const widget = ref(false)
const widgetData = ref<any>(null)
const widgetCountdown = ref<number>(0)

const route = useRoute()
const router = useRouter()

// 2追剧/最近阅读的页面，3为剧场页面/书城首页，4为阅读页/播放页面，5为福利页面，6为个人中心
const positionTypeMap: Record<string, number> = {
  history: 2,
  home: 3,
  chapter: 4,
  welfare: 5,
  member: 6,
  mm: 7,
  category: 8,
}

function getCurrentType() {
  return positionTypeMap[route.name as string]
}

const eventBus = useEventBus<string>('modal:operationalPosition')
eventBus.on(async (event: string) => {
  if (event === 'fetch')
    fetchOperationPosition(getCurrentType())
})

const bizModalRechargeInterception = inject<any>(
  'bizModalRechargeInterception',
)

watch(
  () => route.name,
  () => {
    modal.value = false
    widget.value = false
    const type = positionTypeMap[route.name as string]
    if (!type)
      return
    // Note: chapter 页面有多个弹窗, 需要通过事件触发, 避免重复
    if (route.name === 'chapter')
      return
    // Note: 如果是企微导粉奖励, 会从首页过度跳转, 不请求运营位
    if (route.name === 'home' && route.query.consumeType === 'wpim')
      return

    fetchOperationPosition(type)
  },
  { immediate: true },
)

let timer: any
onUnmounted(() => {
  if (timer) {
    widgetCountdown.value = 0
    clearTimeout(timer)
  }
})

// Note: 老活动, 每天只弹一次
const localActivityMap = useLocalStorage(
  STORAGE_ACTIVITY_MAP,
  {} as Record<string, any>,
)
const toady = getToday()
onMounted(() => {
  if (localActivityMap.value[toady])
    return

  // Clean old data
  localActivityMap.value = {}
  localActivityMap.value[toady] = {}
})

async function fetchOperationPosition(positionType: number) {
  const params = {
    positionType,
    oaaType: '' as any,
  }

  // welfare, member
  if (['welfare', 'member'].includes(route.name as string))
    params.oaaType = 4

  const { data } = await api.get<null, GetOpResourceGetopresourceinfoResponse>(
    '/op/resource/getOpResourceInfoNew',
    {
      params,
    },
  )
  if (!data || !data.config)
    return
  const { 4: modalConfig, 5: widgetConfig } = data.config as any

  // Note: 如果存在充值拦截就不显示运营位弹窗
  // FIXME: simple wait 充值拦截接口
  await sleep(500)
  const type = positionTypeMap[route.name as string]
  if (!type)
    // 避免请求延迟, 页面跳转
    return

  if (modalConfig && !bizModalRechargeInterception?.value.model) {
    modalData.value = modalConfig

    // 老活动, 每天只弹一次
    if (modalConfig.activityOriginType === 2) {
      if (!localActivityMap.value[toady][type]) {
        set(localActivityMap.value[toady], type, true)
        modal.value = true
      }
    }
    else {
      modal.value = true
    }

    _track(modalConfig, 13)
  }
  if (widgetConfig) {
    widgetData.value = widgetConfig
    widget.value = true
    if (widgetData.value.showType === 2) {
      widgetCountdown.value = widgetData.value.showTimes
      countdown()
      function countdown() {
        if (timer)
          clearTimeout(timer)
        timer = setTimeout(() => {
          widgetCountdown.value--
          if (widgetCountdown.value)
            countdown()
          else widget.value = false
        }, 1000)
      }
    }

    _track(widgetConfig, 14)
  }

  // 13: 弹窗, 14: 浮窗
  function _track(config: any, sourceType: number) {
    trackRealtime(
      'pageView',
      {
        activityId: config.id,
        applyActivityId: config.activityId,
        type: 'rechargeOaa',
        mode: 'realtime',
        oaaType: config.oaaType,
        sourceId: config.id,
        sourceType: { 13: 'float', 14: 'pop' }[sourceType],
        userToken: $getToken(),
        positionType: getCurrentType(),
      },
      { sourceId: config.id, sourceType },
    )

    // Note: 如果非运营位活动不上报埋点
    if (config.activityOriginType !== 2) {
      window.collectEvent('pageview', {
        itemName: { 13: '运营位弹窗', 14: '运营位浮窗' }[sourceType],
        itemType: { 13: 'float', 14: 'pop' }[sourceType],
        itemPage: {
          2: '最近阅读',
          3: '书城',
          4: '阅读页',
          5: '福利页',
          6: '我的',
          7: '女频',
          8: '分类',
        }[getCurrentType()],
      })
    }
  }
}

function gotoActivity(data: any, sourceType: number) {
  const { activityId: id } = data

  trackRealtime(
    'activityReceived',
    {
      activityId: data.id,
      applyActivityId: data.activityId,
      type: 'rechargeOaa',
      mode: 'realtime',
      oaaType: data.oaaType,
      sourceId: data.id,
      sourceType: { 13: 'float', 14: 'pop' }[sourceType],
      userToken: $getToken(),
      positionType: getCurrentType(),
    },
    { sourceId: data.id, sourceType },
  )

  // Note: 如果非运营位活动不上报埋点
  if (data.activityOriginType !== 2) {
    window.collectEvent('activityReceived', {
      itemName: { 13: '运营位弹窗', 14: '运营位浮窗' }[sourceType],
      itemType: { 13: 'float', 14: 'pop' }[sourceType],
      itemPage: {
        2: '最近阅读',
        3: '书城',
        4: '阅读页',
        5: '福利页',
        6: '我的',
        7: '女频',
        8: '分类',
      }[getCurrentType()],
    })
  }

  modal.value = false
  router.replace({
    name: 'activity',
    params: { activityId: id },
    query: {
      // Note: 是否是老的活动? 1: 运营位活动, 2: 老活动
      type: data.activityOriginType === 2 ? '' : 'operational',
      sourceId: data.id,
    },
  })
}

defineExpose({
  modal,
  widget,
})
</script>

<template>
  <div v-if="modal || widget">
    <!-- Modal -->
    <BasicModal v-model="modal" :transparent="true">
      <img
        v-if="modal"
        class="w-full -mt-8"
        :src="modalData.imageFileIdUrl"
        @click="gotoActivity(modalData, 13)"
      >
    </BasicModal>

    <!-- Widget  -->
    <div v-if="widget" class="fixed z-5 right-0 bottom-60">
      <div v-if="widgetCountdown" class="absolute -top-6 right-0 text-center">
        <span
          class="px-1 block-inline w-4 text-sm font-bold text-white"
          style="
            font-feature-settings: 'liga' 0;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
          "
        >{{ widgetCountdown }} s</span>
      </div>
      <div v-else class="absolute -top-2 right-0" @click="widget = false">
        <i-assets-icon-close-modal class="w-4 h-4 fill-#00000040!" />
      </div>
      <img
        :src="widgetData.imageFileIdUrl"
        class="w-16 aspect-1/1"
        alt="图片: 挂件"
        @click="gotoActivity(widgetData, 14)"
      >
    </div>
  </div>
</template>
