<script lang="ts" setup>
withDefaults(
  defineProps<{
    value: boolean
    closable?: boolean
    transparent?: boolean
    order?: number
    isFull?: boolean
  }>(),
  {
    order: 1, // TODO: Auto increment
    closable: true,
  },
)

const emit = defineEmits<{
  (event: 'input', value: boolean): void
}>()

function onClose() {
  emit('input', false)
}
</script>

<template>
  <portal to="root" :order="order">
    <transition name="fade">
      <div v-if="value" class="fixed inset-0 z-99">
        <div class="fixed inset-0 bg-#000000B2" />
        <div
          class="w-300px rounded-5px absolute top-1/2 left-1/2 -translate-1/2 z-100"
          :class="{
            'bg-white': !transparent,
            'w-full': isFull,
          }"
        >
          <div
            v-if="closable" class="absolute right-0 -top-30px" :class="{ 'right-10': isFull }" :order="order"
            @click="onClose"
          >
            <i-assets-icon-close-modal class="w-7.5 h-7.5" />
          </div>
          <slot />
        </div>
      </div>
    </transition>
  </portal>
</template>
