<script lang="ts" setup>
import { computed, onMounted, provide, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useFocus, useInfiniteScroll } from '@vueuse/core'
import { api } from '@/api'
import { diffSeconds } from '@/utils'
import { COLUMN_TYPE, CONTENT_CHANNEL, RESOURCE_TYPE, TRACK_CHANNEL_NAME_VALUE } from '@/constants'
import { useConfigStore, useHistoryStore, useToastStore, useTrackValueStore } from '@/stores'

const props = withDefaults(
  defineProps<{
    channel?: 'mm' | ''
  }>(),
  {
    channel: '',
  },
)

const router = useRouter()
const route = useRoute()
function onChangeChannel(value: 'home' | 'mm') {
  router.replace({ name: value })
}

const inputRef = ref<HTMLInputElement>()
const input = ref('')
const { focused: inputFocused } = useFocus(inputRef)
function onSearch() {
  router.replace({ name: 'search', query: { kw: input.value } })
}

function gotoNav(nav: any) {
  if (nav.resourceType === RESOURCE_TYPE.CONTENT) {
    router.replace({ name: 'book', params: { contentId: nav.resourceValue } })
    return
  }

  if (nav.resourceLink === route.path)
    return

  router.replace({ path: nav.resourceLink })
}

function gotoBanner(banner: any) {
  if (!banner.content)
    return gotoNav(banner)

  const trackValueStore = useTrackValueStore()
  trackValueStore.set({ bookId: banner.content.id, firstOrigin: 2, originName: 2, chlName: TRACK_CHANNEL_NAME_VALUE.BANNER })
  router.replace({
    name: 'book',
    params: { contentId: banner.content.id },
    query: { channelName: TRACK_CHANNEL_NAME_VALUE.BANNER.toString() },
  })
}

// Note: report for task for browse home
// TODO: move to `/welfare`?
onMounted(() => {
  api.post<any, PostTaskMemberFinishResponse>('/task/member/finish', { type: 1, detailType: 9, count: 1 })
})

// Modules
const skeleton = ref(false)
const modules = ref<any[]>([])
async function fetchModules() {
  skeleton.value = true
  try {
    const { data } = await api.get<any, GetContentGetcontentlistResponse>('/content/getContentList', {
      params: {
        contentChannel: props.channel === '' ? CONTENT_CHANNEL.MAN : CONTENT_CHANNEL.WOMAN,
      },
    })
    // Filter `resourceList` is empty
    modules.value = (data ?? []).filter((item: any) => item.resourceList.length > 0)
  }
  catch (error: any) {
    console.error(error)
  }
  finally {
    skeleton.value = false
  }
}
onMounted(() => {
  fetchModules()
})
function gotoCollection(id: string) {
  router.replace({ name: 'collection', params: { columnId: id } })
}
function gotoBook(id: string, channelName?: number = 0) {
  const trackValueStore = useTrackValueStore()
  trackValueStore.set({ bookId: id, firstOrigin: 2, originName: 2, chlName: channelName })
  router.replace({
    name: 'book',
    params: { contentId: id },
    query: {
      channelName: channelName?.toString(),
    },
  })
}

// Feed
const feeds = ref<any[]>([])
const feedPage = ref(1)
const feedFetching = ref(false)
const feedEnding = ref(false)
const feedError = ref<any>()
const feedPagination = computed(() => ({
  pageIndex: feedPage.value,
  pageSize: 20,
}))
async function fetchFeed() {
  feedFetching.value = true
  try {
    const { data } = await api.get<any, GetContentGetfeedcontentlistResponse>('/content/getFeedContentList', {
      params: {
        ...feedPagination.value,
        contentChannel: props.channel === '' ? CONTENT_CHANNEL.MAN : CONTENT_CHANNEL.WOMAN,
      },
    })
    feeds.value = [...feeds.value, ...data.records]
    if (feedPage.value >= data.pages)
      feedEnding.value = true
    else
      feedPage.value += 1
  }
  catch (error) {
    feedError.value = error
  }
  finally {
    feedFetching.value = false
  }
}
onMounted(() => {
  fetchFeed()
})

const infiniteRef = ref<HTMLElement>()
provide('pageRef', infiniteRef)
useInfiniteScroll(infiniteRef,
  async () => {
    if (feedFetching.value || feedEnding.value || feedError.value)
      return
    await fetchFeed()
  },
  { distance: 50 },
)

// Watch route `channel` change
watch(() => props.channel, async () => {
  feedFetching.value = false
  feedEnding.value = false
  feedPage.value = 1
  feeds.value = []
  await Promise.all([
    fetchModules(),
    fetchFeed(),
  ])
})

// 企微导粉奖励
const configStore = useConfigStore()
const { $toast } = useToastStore()
const showQwRewards = ref(false) // 是否展示企微奖励弹窗
const qwRewards = ref<null | object>({}) // 企微奖励
function onCloseQwRewardsDialog() {
  showQwRewards.value = false
  qwRewards.value = null
  configStore.updateStrategy(null)
}
async function getQwRewardsInfo() {
  const query = route.query
  if (query.linkCode) {
    window.collectEvent('pageview', {
      page: '阅读页',
      popup_name: '企微二维码',
      link_code: query.linkCode,
    })
  }
  if (query?.consumeCode && query?.coinNum && query?.consumeType === 'wpim' && !configStore.alreadyGetStratrgy) {
    configStore.updateStrategy({
      reward: Number(query.coinNum),
      strategy: query.consumeCode,
      consumeType: query.consumeType,
      qrcodeType: query.qrcode_type,
      guideType: query.guide_type === '1' ? '强制关注' : '引导关注',

    })
    const { data } = await api.get<any, GetReadHistoryListResponse>('/read/history/list', {
      params: {
        pageIndex: 1,
        pageSize: 20,
      },
    })
    if (!data.total) {
      // 上报接口告知服务端
      api.post<any, PostReadReportResponse>('/wp/fans/consume/code', {
        consumeCode: query.consumeCode,
        type: 'wpim',
      }).then((data) => {
        if (data.data) {
          configStore.updateAlreadyGetStratrgy(true)
          showQwRewards.value = true
          qwRewards.value = {
            coin: query.coinNum,
            strategyId: query.consumeCode,
          }
          // 火山埋点
          window.collectEvent('popupShow', {
            page: '书城',
            popup_name: '福利弹窗',
            link_code: query.consumeCode,
          })
        }
      })
    }
    else {
      const current = data.records[0]
      if (current.offlineFlag) {
        $toast('很抱歉，该书已下架')
        // 上报接口告知服务端
        api.post<any, PostReadReportResponse>('/wp/fans/consume/code', {
          consumeCode: query.consumeCode,
          type: 'wpim',
        }).then((data) => {
          if (data.data) {
            configStore.updateAlreadyGetStratrgy(true)
            showQwRewards.value = true
            qwRewards.value = {
              coin: query.coinNum,
              strategyId: query.consumeCode,
            }
            // 火山埋点
            window.collectEvent('popupShow', {
              page: '书城',
              popup_name: '福利弹窗',
              link_code: query.consumeCode,
            })
          }
        })
      }
      else {
        // Note: 清除路由堆栈, 避免返回的时候还写阿迪企微奖励相关参数
        const historyStore = useHistoryStore()
        historyStore.$clear()
        router.replace({
          name: 'chapter',
          params: {
            contentId: current.contentId,
            chapterId: current.contentChapterId,
          },
        })
      }
    }
  }
}
getQwRewardsInfo()
</script>

<template>
  <div ref="infiniteRef" class="h-screen overflow-y-scroll">
    <div class="px-15px pb-20">
      <!-- Tab & Search -->
      <div class="py-2.5 -mb-15px flex items-center space-x-8 sticky top-0 bg-#F4F5F7 z-20 relative">
        <BizTrackClick :class="{ 'opacity-0': inputFocused }" name="男频" type="link">
          <div
            class="text-14px text-#333"
            :class="{ 'text-17px font-bold text-black': channel === '' }"
            @click="onChangeChannel('home')"
          >
            男频
            <span v-show="channel === ''" class="block w-8 h-1 bg-#FF7D00 rounded" />
          </div>
        </BizTrackClick>
        <BizTrackClick :class="{ 'opacity-0': inputFocused }" name="女频" type="link">
          <div
            class="text-14px text-#333"
            :class="{ 'text-17px font-bold text-black': channel === 'mm' }"
            @click="onChangeChannel('mm')"
          >
            女频
            <span v-show="channel === 'mm'" class="block w-8 h-1 bg-#FF7D00 rounded" />
          </div>
        </BizTrackClick>
        <BizTrackClick name="搜索" type="link" class="w-50 absolute top-1.5 right-0 transition-all" :class="{ 'w-full!': inputFocused }">
          <div class="flex items-center space-x-5 relative z-10">
            <i-assets-icon-search class="w-4 h-4 absolute left-3 top-2.5 text-#86909C" />
            <input
              ref="inputRef"
              v-model="input" enterkeyhint="search"
              class="!ml0 flex-1 h-9 w-full pl-9 pr-4 rounded-full border-0 !outline-0 truncate caret-primary !bg-#000 !bg-opacity-4 placeholder:text-14px"
              placeholder="请输入书名/口令搜索"
              @keyup.enter="onSearch"
            >
          </div>
          <div v-if="inputFocused" class="fixed inset-0 bg-white w-full h-100vh" />
        </BizTrackClick>
      </div>

      <!-- Modules -->
      <div v-if="skeleton" class="h-screen">
        <div class="pt-20vh flex justify-center">
          <BasicSpinner class="mx-auto" />
        </div>
        <p class="mt-4 text-center font-bold">
          加载中...
        </p>
      </div>
      <div v-else>
        <div v-for="col in modules" :key="col.id" class="mt-3.75">
          <template v-if="col.columnType === COLUMN_TYPE.BANNER">
            <BizBanner
              :urls="col.resourceList.map(item => item.resourceFileUrl)"
              @click="index => gotoBanner(col.resourceList[index])"
            />
          </template>
          <template v-else-if="col.columnType === COLUMN_TYPE.NAV">
            <div class="py-4 grid grid-cols-4 bg-white rounded">
              <div v-for="item in col.resourceList.slice(0, 4)" :key="item.id">
                <BizTrackClick :name="item.resourceName" type="link">
                  <div class="flex flex-col justify-center items-center" @click="gotoNav(item)">
                    <img class="w-45px h-45px" :src="item.resourceFileUrl" alt="图标:男生">
                    <p class="w-56px text-13px text-#333 text-center mt-1">
                      {{ item.resourceName }}
                    </p>
                  </div>
                </BizTrackClick>
              </div>
            </div>
          </template>
          <template v-else-if="col.columnType === COLUMN_TYPE.ROW_1_COL_4">
            <div class="px-3.75 py-5 bg-white rounded">
              <div class="flex items-baseline justify-between -mb-10px">
                <p class="text-17px font-bold text-black">
                  {{ col.showName }}
                </p>
                <BizTrackClick
                  :name="col.showName" type="link"
                  :extra="{ columnId: col.id }"
                >
                  <p class="text-13px text-#999 flex items-center" @click="gotoCollection(col.id)">
                    <span>更多</span>
                    <i-icon-park-outline-right />
                  </p>
                </BizTrackClick>
              </div>
              <BizTrackClick
                v-if="col.resourceList[0]"
                :name="col.resourceList[0].content.contentName" type="link"
                :extra="{ contentId: col.resourceList[0].content.id }"
              >
                <div
                  class="mt-5 flex items-center space-x-15px"
                  @click="gotoBook(col.resourceList[0].content.id, TRACK_CHANNEL_NAME_VALUE.CHIEF_RECOMMEND)"
                >
                  <img class="w-18 h-25 rounded-3px" :src="col.resourceList[0].content.contentCoverUrl" alt="xxx">
                  <div>
                    <p class="text-15px font-bold line-clamp-1">
                      {{ col.resourceList[0].content.contentName }}
                    </p>
                    <p class="text-13px mt-10px text-#666 line-clamp-2">
                      {{ col.resourceList[0].content.introduce }}
                    </p>
                    <p v-if="col.resourceList[0].content.tagList.length" class="mt-10px space-x-5px flex flex-wrap h-24px overflow-hidden">
                      <span
                        v-for="tag in col.resourceList[0].content.tagList" :key="tag.id"
                        class="px-1 py-0.5 text-12px text-#FF7D00 bg-#FFF7E8 rounded-2px"
                      >
                        {{ tag.name }}
                      </span>
                    </p>
                  </div>
                </div>
              </BizTrackClick>
              <div class="mt-5 grid grid-cols-4 gap-9px">
                <div v-for="item in col.resourceList.slice(1, 5)" :key="item.id">
                  <BizTrackClick
                    :name="item.content.contentName" type="link"
                    :extra="{ contentId: item.content.id }"
                  >
                    <div
                      @click="gotoBook(item.content.id, TRACK_CHANNEL_NAME_VALUE.CHIEF_RECOMMEND)"
                    >
                      <img class="w-18 h-25 rounded-3px" :src="item.content.contentCoverUrl" alt="图片:书封">
                      <p class="mt-10px text-14px line-clamp-2">
                        {{ item.content.contentName }}
                      </p>
                    </div>
                  </BizTrackClick>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="col.columnType === COLUMN_TYPE.COL_3_FREE">
            <div class="bg-white rounded">
              <div
                class="
                  -mb-5 w-full h-57px px-15px flex items-center justify-between relative
                  bg-gradient-to-r from-#FF926666 to-#FF4F4900 rounded-t
                "
              >
                <img class="absolute w-30px top-0 -left-7px" src="@/assets/icon-flash-coin.png" alt="图标:金币">
                <img class="absolute w-32px top-4px left-60%" src="@/assets/icon-flash-line.png" alt="图标:光线">
                <p class="text-17px font-bold">
                  {{ col.showName }}
                </p>
                <BasicCountdown class="z-10" :seconds="diffSeconds(Date.now(), col.effectiveEndTime)" />
                <BizTrackClick
                  :name="col.showName" type="link"
                  :extra="{ columnId: col.id }"
                >
                  <p class="flex items-center text-13px text-#666" @click="gotoCollection(col.id)">
                    更多
                    <i-icon-park-outline-right />
                  </p>
                </BizTrackClick>
              </div>
              <div class="p-15px">
                <div v-for="item in col.resourceList" :key="item.id">
                  <BizTrackClick
                    :name="item.content.contentName" type="link"
                    :extra="{ contentId: item.content.id }"
                  >
                    <div class="mt-5 flex items-center space-x-15px" @click="gotoBook(item.content.id, TRACK_CHANNEL_NAME_VALUE.MAIN_RECOMMEND)">
                      <img class="w-18 h-25 rounded-3px" :src="item.content.contentCoverUrl" alt="图片:书封">
                      <div class="flex-1">
                        <p class="text-15px font-bold line-clamp-1">
                          {{ item.content.contentName }}
                        </p>
                        <p class="text-13px mt-10px text-#666 line-clamp-2">
                          {{ item.content.introduce }}
                        </p>
                        <p class="mt-10px flex space-x-10px text-13px">
                          <span class="text-#FF7D00">免费</span>
                          <span class="line-through text-#999">{{ item.content.priceAmount ?? 0 }}元/千字</span>
                        </p>
                      </div>
                    </div>
                  </BizTrackClick>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="col.columnType === COLUMN_TYPE.ROW_4">
            <div class="mt-3.75 px-3.75 py-5 bg-white rounded overflow-hidden">
              <div class="-mb-5px flex items-baseline justify-between">
                <p class="text-17px font-bold text-black">
                  {{ col.showName }}
                </p>
                <BizTrackClick
                  :name="col.showName" type="link"
                  :extra="{ columnId: col.id }"
                >
                  <p class="text-13px text-#999 flex items-center" @click="gotoCollection(col.id)">
                    <span>更多</span>
                    <i-icon-park-outline-right />
                  </p>
                </BizTrackClick>
              </div>
              <div class="mt-15px grid grid-cols-4 gap-9px">
                <div v-for="item in col.resourceList.slice(0, 4)" :key="item.id">
                  <BizTrackClick
                    :name="item.content.contentName" type="link"
                    :extra="{ contentId: item.content.id }"
                  >
                    <div @click="gotoBook(item.content.id, TRACK_CHANNEL_NAME_VALUE.STRONG_RECOMMEND)">
                      <img class="w-18 h-25 rounded-3px" :src="item.content.contentCoverUrl" alt="图片:书封">
                      <p class="mt-10px text-14px line-clamp-2">
                        {{ item.content.contentName }}
                      </p>
                    </div>
                  </BizTrackClick>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="col.columnType === COLUMN_TYPE.COL_3_NORMAL">
            <div class="mt-3.75 px-3.75 py-5 bg-white rounded">
              <div class="flex items-baseline justify-between -mb-10px">
                <p class="text-17px font-bold text-black">
                  {{ col.showName }}
                </p>
                <BizTrackClick
                  :name="col.showName" type="link"
                  :extra="{ columnId: col.id }"
                >
                  <p class="text-13px text-#999 flex items-center" @click="gotoCollection(col.id)">
                    <span>更多</span>
                    <i-icon-park-outline-right />
                  </p>
                </BizTrackClick>
              </div>
              <div v-for="item in col.resourceList" :key="item.id">
                <BizTrackClick
                  :name="item.content.contentName" type="link"
                  :extra="{ contentId: item.content.id }"
                >
                  <div class="mt-5 flex items-center space-x-15px" @click="gotoBook(item.content.id, TRACK_CHANNEL_NAME_VALUE.HOT_RECOMMEND)">
                    <img class="w-18 h-25 rounded-3px" :src="item.content.contentCoverUrl" alt="图片:书封">
                    <div class="flex-1">
                      <p class="text-15px font-bold line-clamp-1">
                        {{ item.content.contentName }}
                      </p>
                      <p class="text-13px mt-10px text-#666 line-clamp-2">
                        {{ item.content.introduce }}
                      </p>
                      <div class="mt-10px flex justify-between text-13px text-#999">
                        <p>
                          <span>{{ item.content.finishStatus ? '已完结' : '未完结' }}</span>
                          <span v-if="item.content.tagList[0]"> | </span>
                          <span>{{ item.content.tagList[0]?.name }}</span>
                        </p>
                        <span>{{ item.content.bookshelfCount ?? 0 }} 人在追</span>
                      </div>
                    </div>
                  </div>
                </BizTrackClick>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- Feed -->
      <div v-if="feeds.length" class="mt-3.75 px-3.75 py-5 bg-white rounded">
        <div class="flex items-baseline justify-between -mb-10px">
          <p class="text-17px font-bold text-black">
            精品好文 吐血安利
          </p>
        </div>

        <div v-for="(item, index) in feeds" :key="index">
          <BizTrackClick
            :name="item.contentName" type="link"
            :extra="{ contentId: item.id }"
          >
            <div class="mt-5 flex items-center space-x-15px" @click="gotoBook(item.id, TRACK_CHANNEL_NAME_VALUE.HIGH_QUALITY)">
              <img class="w-18 h-25 rounded-3px" :src="item.contentCoverUrl" alt="图片:书封">
              <div class="flex-1">
                <p class="text-15px font-bold line-clamp-1">
                  {{ item.contentName }}
                </p>
                <p class="text-13px mt-10px text-#666 line-clamp-2 break-anywhere">
                  {{ item.introduce }}
                </p>
                <div class="mt-10px flex justify-between text-13px text-#999">
                  <p>
                    <span>{{ item.finishStatus ? '已完结' : '未完结' }}</span>
                    <span v-if="item.tagList[0]"> | </span>
                    <span>{{ item.tagList[0]?.name }}</span>
                  </p>
                  <span>{{ item.bookshelfCount ?? 0 }} 人在追</span>
                </div>
              </div>
            </div>
          </BizTrackClick>
        </div>
      </div>
      <div v-if="feedEnding" class="py-15px text-13px text-#999 text-center">
        我们是有底线的~
      </div>
      <div v-else-if="feedFetching" class="py-15px flex items-center justify-center space-x-4 text-13px text-#999">
        <div class="animate-spin">
          <i-icon-park-outline-loading />
        </div>
        <p>加载中...</p>
      </div>
    </div>

    <BizWidgetLatestChapter v-show="!inputFocused" />

    <BasicTabbar v-show="!inputFocused" />

    <!-- Modal: 企微导粉奖励 -->
    <BasicModal v-model="showQwRewards">
      <div class="p-5 text-center">
        <div class="font-bold">
          <p class="text-14px">
            天降福利
          </p>
          <p class="text-18px">
            恭喜您获得<span class="text-red">{{ qwRewards?.coin }}</span>金币
          </p>
        </div>
        <a
          class="block mt-4 w-full h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
          @click="onCloseQwRewardsDialog"
        >
          领取奖励
        </a>
      </div>
    </BasicModal>
  </div>
</template>
